<template>
  <div>
    <div class="table_box pt-3 pb-3">
      <el-table :data="displayData" style="width: 100%">
        <el-table-column prop="date" :label="$t('common.column.date')" min-width="100">
          <template slot-scope="scope">{{ scope.row.date | dateFormat }}</template>
        </el-table-column>
        <el-table-column :label="$t('common.key.from')" min-width="100">
          <template slot-scope="scope">{{ scope.row.fromAccount + ' (' + scope.row.from_currency + ')' }}</template>
        </el-table-column>
        <el-table-column :label="$t('common.key.to')" min-width="100">
          <template slot-scope="scope">{{ scope.row.toAccount + ' (' + scope.row.to_currency + ')' }}</template>
        </el-table-column>
        <el-table-column prop="amount" :label="$t('common.column.amt')" min-width="100">
          <template slot-scope="scope">{{ scope.row.amount | formatNumber }}</template>
        </el-table-column>
        <el-table-column :label="$t('common.column.status')" min-width="100">
          <template slot-scope="scope">{{ scope.row.status | payStatus }}</template>
        </el-table-column>
        <el-table-column :label="$t('common.column.procNote')" min-width="100">
          <template slot-scope="scope">
            <div class="text-primary">{{ scope.row.notes }}</div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <pagination v-bind:table-data="tableData" v-bind:display-data.sync="displayData"></pagination>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination';
import { apiTransferHistoryGet } from '@/resource';

export default {
  components: { Pagination },
  data() {
    return {
      userId: this.$store.state.common.uuid,
      tableData: [],
      displayData: []
    };
  },
  mounted() {
    apiTransferHistoryGet({ userId: this.userId }).then(response => {
      this.tableData = response.data;
    });
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/transactionHistory.scss';
</style>
