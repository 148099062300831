<template>
  <div>
    <div class="table_box pt-3 pb-3">
      <el-table :data="withdrawTable" style="width: 100%">
        <el-table-column prop="date" :label="$t('common.column.date')" min-width="100"></el-table-column>
        <el-table-column :label="$t('common.column.tradingAcc')" min-width="100">
          <template slot-scope="accountScope">
            {{ accountScope.row.account + ' (' + accountScope.row.currency + ')' }}
          </template>
        </el-table-column>
        <el-table-column :label="$t('common.column.method')" min-width="100">
          <template slot-scope="methodScope">
            <span>{{ showWithdrawMethod(methodScope.row.method, methodScope.row.transferCountry) }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('common.column.amt')" min-width="100">
          <template slot-scope="amountScope">
            {{ amountScope.row.currency | currencyToSymbol }}
            {{ amountScope.row.amount | formatNumber(2) }}
          </template>
        </el-table-column>
        <el-table-column :label="$t('common.column.status')" min-width="100">
          <template slot-scope="statusScope">
            <div
              :class="
                getStatusColor(
                  getWithdrawStatus(statusScope.row.status, statusScope.row.processedByOp, statusScope.row.reversedType)
                )
              "
            >
              {{
                getWithdrawStatus(statusScope.row.status, statusScope.row.processedByOp, statusScope.row.reversedType)
                  | transactionStatus
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column min-width="100" v-if="showCancelWithdraw">
          <template slot-scope="statusScope">
            <el-button
              v-if="showCancelButton(statusScope.row.status, statusScope.row.processedByOp, statusScope.row.method)"
              @click="cancelWithdraw(statusScope.row)"
              size="mini"
              round
              plain
              >{{ $t('common.button.cancel') }}</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页 -->
    <div class="page_box clearfix mb-3 text-right">
      <el-pagination
        :page-size="10"
        layout="total, sizes, prev, pager, next"
        :total="withdrawTotal"
        :current-page.sync="withdrawPageNo"
        :page-sizes="[10, 25, 50, 100]"
        @current-change="handleWithdrawCurrentChange"
        @size-change="handleWithdrawSizeChange"
        data-testid="pagination"
      ></el-pagination>
    </div>
    <!-- status -->
    <div class="status_box">
      <div class="title text-waikawa-gray bg-mirage-gray">{{ $t('common.field.statusDef') }}</div>
      <ul>
        <li class="d-flex flex-wrap align-content-center me-0 pt-3 pb-3 pe-5 ps-5">
          <div class="tag bg-primary text-center text-white pt-1 pb-1 pe-2 ps-2">
            {{ $t('common.field.submitted') }}
          </div>
          <p class="pt-1 pb-1 pe-0 pe-md-3 ps-0 ps-md-3 text-white">{{ $t('transcationHistory.withdrawDesc.sub') }}</p>
        </li>
        <li class="d-flex flex-wrap align-content-center me-0 pt-3 pb-3 pe-5 ps-5">
          <div class="tag bg-primary text-center text-white pt-1 pb-1 pe-2 ps-2">
            {{ $t('common.field.successful') }}
          </div>
          <p class="pt-1 pb-1 pe-0 pe-md-3 ps-0 ps-md-3 text-white">{{ $t('transcationHistory.withdrawDesc.suc') }}</p>
        </li>
        <li class="d-flex flex-wrap align-content-center me-0 pt-3 pb-3 pe-5 ps-5">
          <div class="tag bg-primary text-center text-white pt-1 pb-1 pe-2 ps-2">
            {{ $t('common.field.processing') }}
          </div>
          <i18n
            path="transcationHistory.withdrawDesc.proc"
            tag="p"
            class="pt-1 pb-1 pe-0 pe-md-3 ps-0 ps-md-3 text-white"
          >
            <template v-slot:mailTo>
              <a :href="'mailto:' + GLOBAL_CONTACT_EMAIL">{{ GLOBAL_CONTACT_EMAIL }}</a>
            </template>
          </i18n>
        </li>
        <li class="d-flex flex-wrap align-content-center me-0 pt-3 pb-3 pe-5 ps-5">
          <div class="tag bg-primary text-center text-white pt-1 pb-1 pe-2 ps-2">
            {{ $t('common.field.incomplete') }}
          </div>
          <i18n
            path="transcationHistory.withdrawDesc.incomp"
            tag="p"
            class="pt-1 pb-1 pe-0 pe-md-3 ps-0 ps-md-3 text-white"
          >
            <template v-slot:mailTo>
              <a :href="'mailto:' + GLOBAL_CONTACT_EMAIL">{{ GLOBAL_CONTACT_EMAIL }}</a>
            </template>
          </i18n>
        </li>
        <li class="d-flex flex-wrap align-content-center me-0 pt-3 pb-3 pe-5 ps-5">
          <div class="tag bg-primary text-center text-white pt-1 pb-1 pe-2 ps-2">
            {{ $t('common.field.cancelled') }}
          </div>
          <i18n
            path="transcationHistory.withdrawDesc.cancel"
            tag="p"
            class="pt-1 pb-1 pe-0 pe-md-3 ps-0 ps-md-3 text-white"
          >
            <template v-slot:mailTo>
              <a :href="'mailto:' + GLOBAL_CONTACT_EMAIL">{{ GLOBAL_CONTACT_EMAIL }}</a>
            </template>
          </i18n>
        </li>
        <li class="d-flex flex-wrap align-content-center me-0 pt-3 pb-3 pe-5 ps-5">
          <div class="tag bg-primary text-center text-white pt-1 pb-1 pe-2 ps-2">
            {{ $t('common.field.failed') }}
          </div>
          <i18n
            path="transcationHistory.withdrawDesc.fail"
            tag="p"
            class="pt-1 pb-1 pe-0 pe-md-3 ps-0 ps-md-3 text-white"
          >
            <template v-slot:mailTo>
              <a :href="'mailto:' + GLOBAL_CONTACT_EMAIL">{{ GLOBAL_CONTACT_EMAIL }}</a>
            </template>
          </i18n>
        </li>
        <li class="d-flex flex-wrap align-content-center me-0 pt-3 pb-3 pe-5 ps-5">
          <div class="tag bg-primary text-center text-white pt-1 pb-1 pe-2 ps-2">
            {{ $t('common.field.rejected') }}
          </div>
          <i18n
            path="transcationHistory.withdrawDesc.rej"
            tag="p"
            class="pt-1 pb-1 pe-0 pe-md-3 ps-0 ps-md-3 text-white"
          >
            <template v-slot:mailTo>
              <a :href="'mailto:' + GLOBAL_CONTACT_EMAIL">{{ GLOBAL_CONTACT_EMAIL }}</a>
            </template>
          </i18n>
        </li>
      </ul>
    </div>
    <vDialog :visible.sync="dialogVisible" :visibleFooter="false" :top="'300px'">
      <div class="text-center">
        <h5 class="mb-4" v-html="$t('transcationHistory.cancelWithdraw')"></h5>
        <el-button plain @click="closeDialog">{{ $t('common.field.no') }}</el-button>
        <el-button type="primary" @click="submitCancelWithdraw()">{{ $t('common.field.yes') }}</el-button>
      </div>
    </vDialog>
  </div>
</template>

<script>
import mixin from '@/mixins/transactions/transactionHistory';
import vDialog from '@/components/home/vDialog';
import { apiCancelWithdraw } from '@/resource';
import withdrawSetting from '@/constants/payment/withdrawSetting';

export default {
  mixins: [mixin],
  components: { vDialog },
  data() {
    return {
      dialogVisible: false,
      cancelInfo: null,
      withdrawPageNo: 1,
      withdrawPageSize: 10,
      withdrawUrl: 'withdraw',
      withdrawTotal: 0,
      withdrawTable: [],
      withdrawMethods: this.$platform.withdrawMethods(this.$store.state.common.regulator),
      withdrawStatus: {
        // WITHDRAW_SUBMIT(1), // 提交中
        // WITHDRAW_CANCEL(2), // 取消
        // WITHDRAW_AUDIT(3), // 审核中
        // WITHDRAW_REFUSE(4), // 拒绝
        // WITHDRAW_ACCEPT(5), // 受理
        // WITHDRAW_PAYFAIL(6), // 打款失败
        // WITHDRAW_SUCCESS(7), // 出金成功
        // WITHDRAW_FAIL(8), // 出金失败
        // WITHDRAW_PENDING(9), // 没有决定的状态，挂着
        // WITHDRAW_HALFFAILED(10); // 部分失败
        // WITHDRAW_REVERSE_FAILED(20,"Reverse processing");
        1: 'Submitted',
        2: 'Cancelled',
        3: 'Processing',
        4: 'Rejected',
        5: 'Processing',
        6: 'Failed',
        7: 'Successful',
        8: 'Failed',
        9: 'Processing',
        10: 'Failed',
        20: 'Processing'
      },
      withdrawReversedType: {
        1: 'Cancelled',
        2: 'Failed',
        3: 'Failed'
      }
    };
  },
  methods: {
    cancelWithdraw(info) {
      this.dialogVisible = true;
      this.cancelInfo = info;
    },
    closeDialog() {
      this.dialogVisible = false;
      this.cancelInfo = null;
    },
    showCancelButton(status, processedByOp, method) {
      return !processedByOp && (status === 1 || status === 3 || status === 5) && method !== 4;
    },
    getWithdrawStatus(status, processedByOp, reversedType) {
      if (reversedType) return this.withdrawReversedType[reversedType];
      return !processedByOp && (status === 3 || status === 5) ? this.withdrawStatus[1] : this.withdrawStatus[status];
    },
    submitCancelWithdraw() {
      apiCancelWithdraw({ withdrawalId: this.cancelInfo.id })
        .then(result => {
          if (result.data.code == 0) {
            this.closeDialog();
            this.initTransactionHistory();
          } else {
            this.showErrorMsg();
          }
        })
        .catch(err => {
          this.showErrorMsg();
        });
    },
    showErrorMsg() {
      this.loading = false;
      this.$message({
        message: this.$t('transcationHistory.cancelError'),
        type: 'error',
        duration: 10000
      });

      this.closeDialog();
      this.initTransactionHistory();
    },
    handleWithdrawCurrentChange(pageNo) {
      this.withdrawPageNo = pageNo;
      this.initTransactionHistory();
    },
    handleWithdrawSizeChange(size) {
      this.withdrawPageSize = size;
      this.initTransactionHistory();
    },
    showWithdrawMethod(method, transferCountry) {
      if (!withdrawSetting[method]) return method;

      let withdrawMethods = withdrawSetting[method][transferCountry]
        ? withdrawSetting[method][transferCountry]
        : withdrawSetting[method].default;

      return this.$options.filters.withdrawMethods(withdrawMethods);
    },
    initTransactionHistory() {
      this.queryTransactionHistory(this.withdrawUrl, this.withdrawPageNo, this.withdrawPageSize).then(resp => {
        if (resp.data.code === 0) {
          this.withdrawTotal = resp.data.data.total;
          this.withdrawTable = resp.data.data.withdrawHistory;
        }
      });
    },
    getStatusColor(status) {
      switch (status) {
        case 'Successful':
          return 'text-green';
        case 'Cancelled':
          return 'text-ghost-gray';
        case 'Failed':
        case 'Rejected':
          return 'text-magenta';
        default:
          return 'text-primary';
      }
    }
  },
  computed: {
    showCancelWithdraw() {
      const found = this.withdrawTable.find(
        element =>
          element.processedByOp === null &&
          (element.status === 1 || element.status === 3 || element.status === 5) &&
          element.method !== 4
      );
      return this.$platform.getCancelWithdraw(this.regulator) && found;
    }
  },
  mounted() {
    this.initTransactionHistory();
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/transactionHistory.scss';
</style>
