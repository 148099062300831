<template>
  <div id="transactionHistory">
    <div class="content_box">
      <div class="inner_max">
        <h3>{{ $t('menu.transactionHis') }}</h3>
        <ul class="tab_demo text-waikawa-gray" :class="{ li_ru: lang === 'ru' }">
          <li :class="{ active: activeName === 'deposit' }" @click="activeName = 'deposit'" data-testid="deposit">
            {{ $t('transcationHistory.deposit') }}
          </li>
          <li :class="{ active: activeName === 'withdraw' }" @click="activeName = 'withdraw'" data-testid="withdraw">
            {{ $t('transcationHistory.withdraw') }}
          </li>
          <li :class="{ active: activeName === 'transfer' }" @click="activeName = 'transfer'" data-testid="transfer">
            {{ $t('menu.tranHis') }}
          </li>
          <li class="export_tool">
            <img class="calendar-image" src="@/assets/images/calendar.png" alt="image" />
            <DateRangePicker
              :startDate="startDate"
              :endDate="endDate"
              :startDateOption="dateOption"
              :endDateOption="dateOption"
              @change="onPickerChange"
            />
            <el-button class="bg-primary" @click="onExportClick">{{ $t('common.button.export') }}</el-button>
          </li>
        </ul>
        <ul class="table_demo">
          <li class="table_item" :class="{ active: activeName === 'deposit' }"><DepositHistory /></li>
          <li class="table_item" :class="{ active: activeName === 'withdraw' }"><WithdrawalHistory /></li>
          <li class="table_item" :class="{ active: activeName === 'transfer' }"><TransferHistory /></li>
        </ul>
      </div>
    </div>
    <v-dialog :visible.sync="dialogVisible">
      <div class="dialog_body">
        <el-form label-position="top" :model="form" ref="form" status-icon>
          <AccountNumber
            supportedCurrencies="all"
            @setCurrency="setCurrency"
            @setAccountNumber="setAccountNumber"
            :showLabel="false"
          ></AccountNumber>
          <div class="dialog_btn_box">
            <el-button @click="onConfirm" data-testid="ok">
              {{ $t('common.button.ok') }}
            </el-button>
            <el-button plain @click="dialogVisible = false" data-testid="cancel">
              {{ $t('common.button.cancel') }}
            </el-button>
          </div>
        </el-form>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import TransferHistory from '@/components/transactions/TransferHistory';
import DepositHistory from '@/components/transactions/DepositHistory';
import WithdrawalHistory from '@/components/transactions/WithdrawalHistory';
import vDialog from '@/components/home/vDialog';
import AccountNumber from '@/components/form/AccountNumber';
import { apiDownloadAccountTransactionHistoryPDF } from '@/resource';
import DateRangePicker from '@/components/DateRangePicker';
import pdfHandler from '@/util/pdfHandler';
import moment from 'moment';

export default {
  name: 'TransactionHistory',
  components: { TransferHistory, DepositHistory, WithdrawalHistory, vDialog, AccountNumber, DateRangePicker },
  data() {
    const defaultRange = 7;
    const exportEndDate = new Date();
    const exportStartDate = new Date(
      exportEndDate.getFullYear(),
      exportEndDate.getMonth(),
      exportEndDate.getDate() - defaultRange
    );
    return {
      activeName: 'deposit',
      startDate: exportStartDate,
      endDate: exportEndDate,
      dateFormat: 'YYYY-MM-DD',
      dialogVisible: false,
      form: {
        accountNumber: null,
        currency: null
      },
      dateOption: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      }
    };
  },
  computed: {
    lang() {
      return this.$store.state.common.lang;
    }
  },
  methods: {
    onExportClick() {
      this.dialogVisible = true;
    },
    setAccountNumber(accountNumber) {
      this.form.accountNumber = accountNumber;
    },
    setCurrency(currency) {
      this.form.currency = currency;
    },
    onPickerChange(val) {
      this.startDate = val[0];
      this.endDate = val[1];
    },
    onConfirm() {
      const reqParam = {
        startDate: moment(this.startDate).format(this.dateFormat),
        endDate: moment(this.endDate).format(this.dateFormat),
        accountNumber: this.form.accountNumber.toString()
      };
      apiDownloadAccountTransactionHistoryPDF(reqParam)
        .then(resp => {
          if (resp.data.code == 0) {
            this.downloadPdf(resp.data.data, reqParam);
          } else {
            this.errorMessage(this.$t('responseMsg.500'));
            this.dialogVisible = false;
          }
        })
        .catch(err => {
          this.errorMessage(this.$t('responseMsg.500'));
          this.dialogVisible = false;
        });
    },
    errorMessage(message) {
      this.$message({
        message: message,
        type: 'error'
      });
    },
    downloadPdf(data, reqParam) {
      const { startDate, endDate, accountNumber } = reqParam;
      const filename = `${accountNumber} ${startDate} to ${endDate}.pdf`;
      pdfHandler(data, filename);
      this.dialogVisible = false;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/transactionHistory.scss';
</style>
