<template>
  <div>
    <div class="table_box pt-3 pb-3">
      <el-table :data="depositTable">
        <el-table-column prop="date" :label="$t('common.column.date')" min-width="100"></el-table-column>
        <el-table-column :label="$t('common.column.tradingAcc')" min-width="100">
          <template slot-scope="accountScope">
            {{ accountScope.row.account + ' (' + accountScope.row.currency + ')' }}
          </template>
        </el-table-column>
        <el-table-column :label="$t('common.column.method')" min-width="100">
          <template slot-scope="methodScope">
            <span>{{ showDepositMethod(methodScope.row.method, methodScope.row.channel) }} </span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('common.column.amt')" min-width="100">
          <template slot-scope="amountScope">
            {{ amountScope.row.currency | currencyToSymbol }}{{ amountScope.row.amount | formatNumber(2) }}
          </template>
        </el-table-column>
        <el-table-column :label="$t('common.column.status')" min-width="100">
          <template slot-scope="statusScope">
            <div :class="getStatusColor(depositStatus[statusScope.row.status])">
              {{ depositStatus[statusScope.row.status] | transactionStatus }}
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页 -->
    <div class="page_box clearfix mb-3 text-right">
      <el-pagination
        :page-size="10"
        layout="total, sizes, prev, pager, next"
        :total="depositTotal"
        :current-page.sync="depositPageNo"
        :page-sizes="[10, 25, 50, 100]"
        @current-change="handleDepositCurrentChange"
        @size-change="handleDepositSizeChange"
        data-testid="pagination"
      ></el-pagination>
    </div>
    <!-- status -->
    <div class="status_box">
      <div class="title text-waikawa-gray bg-mirage-gray">{{ $t('common.field.statusDef') }}</div>
      <ul>
        <li class="me-0 pt-3 pb-3 pe-5 ps-5">
          <div class="tag bg-primary text-center text-white pt-1 pb-1 pe-2 ps-2">
            {{ $t('common.field.incomplete') }}
          </div>
          <p style="width: 85%" class="incomplete pt-1 pb-1 pe-0 pe-md-3 ps-0 ps-md-3 text-white">
            {{ $t('transcationHistory.desc.incomplete') }}
          </p>
        </li>
        <li class="d-flex flex-wrap align-content-center me-0 pt-3 pb-3 pe-5 ps-5">
          <div class="tag bg-primary text-center text-white pt-1 pb-1 pe-2 ps-2">
            {{ $t('common.field.successful') }}
          </div>
          <p class="pt-1 pb-1 pe-0 pe-md-3 ps-0 ps-md-3 text-white">{{ $t('transcationHistory.desc.success') }}</p>
        </li>
        <li class="d-flex flex-wrap align-content-center me-0 pt-3 pb-3 pe-5 ps-5">
          <div class="tag bg-primary text-center text-white pt-1 pb-1 pe-2 ps-2">
            {{ $t('common.field.processing') }}
          </div>
          <i18n path="transcationHistory.desc.proc" tag="p" class="pt-1 pb-1 pe-0 pe-md-3 ps-0 ps-md-3 text-white">
            <template v-slot:mailTo>
              <a :href="'mailto:' + GLOBAL_CONTACT_EMAIL">{{ GLOBAL_CONTACT_EMAIL }}</a>
            </template>
          </i18n>
        </li>
        <li class="d-flex flex-wrap align-content-center me-0 pt-3 pb-3 pe-5 ps-5">
          <div class="tag bg-primary text-center text-white pt-1 pb-1 pe-2 ps-2">
            {{ $t('common.field.failed') }}
          </div>
          <i18n path="transcationHistory.desc.failed" tag="p" class="pt-1 pb-1 pe-0 pe-md-3 ps-0 ps-md-3 text-white">
            <template v-slot:mailTo>
              <a :href="'mailto:' + GLOBAL_CONTACT_EMAIL">{{ GLOBAL_CONTACT_EMAIL }}</a>
            </template>
          </i18n>
        </li>
        <li class="d-flex flex-wrap align-content-center me-0 pt-3 pb-3 pe-5 ps-5">
          <div class="tag bg-primary text-center text-white pt-1 pb-1 pe-2 ps-2">
            {{ $t('common.field.rejected') }}
          </div>
          <i18n path="transcationHistory.desc.rejected" tag="p" class="pt-1 pb-1 pe-0 pe-md-3 ps-0 ps-md-3 text-white">
            <template v-slot:mailTo>
              <a :href="'mailto:' + GLOBAL_CONTACT_EMAIL">{{ GLOBAL_CONTACT_EMAIL }}</a>
            </template>
          </i18n>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import mixin from '@/mixins/transactions/transactionHistory';
import depositHistory from '@/constants/payment/depositHistory';

export default {
  mixins: [mixin],
  data() {
    return {
      depositPageNo: 1,
      depositPageSize: 10,
      depositUrl: 'deposit',
      depositTotal: 0,
      depositTable: [],
      displayData: [],
      depositStatus: {
        1: 'Incomplete',
        2: 'Incomplete',
        3: 'Failed',
        4: 'Processing',
        5: 'Successful',
        6: 'Processing',
        7: 'Rejected',
        8: 'Processing',
        9: 'Processing',
        11: 'Successful'
      }
    };
  },
  methods: {
    handleDepositCurrentChange(pageNo) {
      this.depositPageNo = pageNo;
      this.initTransactionHistory();
    },
    handleDepositSizeChange(size) {
      this.depositPageSize = size;
      this.initTransactionHistory();
    },
    showDepositMethod(method, channel) {
      if (!depositHistory[method]) return method;

      const depositMethods = depositHistory[method][channel]
        ? depositHistory[method][channel].value
        : depositHistory[method].default.value;
      return this.$options.filters.depositMethods(depositMethods);
    },
    initTransactionHistory() {
      this.queryTransactionHistory(this.depositUrl, this.depositPageNo, this.depositPageSize).then(resp => {
        if (resp.data.code === 0) {
          this.depositTotal = resp.data.data.total;
          this.depositTable = resp.data.data.depositHistory;
        }
      });
    },
    getStatusColor(status) {
      switch (status) {
        case 'Successful':
          return 'text-green';
        case 'Incomplete':
          return 'text-ghost-gray';
        case 'Failed':
          return 'text-magenta';
        case 'Rejected':
          return 'text-magenta';
        default:
          return 'text-primary';
      }
    }
  },
  mounted() {
    this.initTransactionHistory();
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/transactionHistory.scss';
</style>
